import { useUserEvents } from '@xylabs/react-pixel'
import type { OverridedMixpanel } from 'mixpanel-browser'
import { default as untypedMixpanel } from 'mixpanel-browser'

import { XyoUserEvents } from './UserEvents.ts'

const mixpanel = untypedMixpanel as unknown as OverridedMixpanel

// we call this outside the function to force initialization on page load
let instance: XyoUserEvents

export const getXyoUserEvents = (mixpanelToken?: string) => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken)
    return instance = instance ?? XyoUserEvents.get(mixpanel)
  }
  return instance = instance ?? XyoUserEvents.get(mixpanelToken ? mixpanel.init(mixpanelToken) : undefined)
}

export const useXyoUserEvents = () => {
  return useUserEvents(true) as XyoUserEvents
}

export { XyoUserEvents } from './UserEvents.ts'
export * from './XyoEventHandler.ts'
export * from './XyoEventHandlerInterface.ts'
