import {
  alpha, AppBar, Slide, useMediaQuery, useScrollTrigger, useTheme,
} from '@mui/material'
import type { AppBarExProps } from '@xylabs/react-appbar'
import { AppBarEx } from '@xylabs/react-appbar'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'

import { ContextToolbar } from './ContextToolbar.tsx'
import { MobileNavDialog } from './MobileDialog/index.ts'
import { SystemToolBar } from './SystemToolbar/index.ts'

interface XyoAppBarExProps extends AppBarExProps {

  customButton?: ReactNode
}
export const XyoAppBar: React.FC<XyoAppBarExProps> = ({
  customButton, style, contextToolbar, systemToolbar, ...props
}) => {
  const theme = useTheme()
  const isNavbarMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const trigger = useScrollTrigger({ target: globalThis.window, threshold: 500 })
  const [isAtTop, setIsAtTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      if (scrollTop < 550) {
        // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
        setIsAtTop(true)
      } else {
        // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
        setIsAtTop(false)
      }
    }

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Perform a check on mount in case the user is already at the top
    handleScroll()

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Slide direction="down" in={!trigger}>
      <AppBar
        color="transparent"
        elevation={0}
      >
        <AppBarEx
          position="sticky"
          container="xl"
          elevation={0}
          style={{
            WebkitBackdropFilter: `blur(${isAtTop ? '5px' : '20px'})`,
            backdropFilter: `blur(${isAtTop ? '5px' : '20px'})`,
            backgroundColor: alpha(theme.palette.background.default, isAtTop ? 0.3 : 0.8),
            color: theme.palette.getContrastText(theme.palette.background.default),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            borderBottom: isAtTop ? 'none' : `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
            ...style,
          }}
          contextToolbar={contextToolbar ?? <ContextToolbar />}
          systemToolbar={
            systemToolbar ?? (
              <SystemToolBar customButton={customButton}>
                {isNavbarMobile
                  ? <MobileNavDialog />
                  : null}
              </SystemToolBar>
            )
          }
          onClick={event => event.stopPropagation()}
          {...props}
        >
          <FlexGrowRow />
        </AppBarEx>
      </AppBar>
    </Slide>
  )
}
