import {
  Container, Typography, useTheme,
} from '@mui/material'
import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EmailNewsletterTextField } from './EmailNewsletterTextField.tsx'

export const Newsletter: React.FC<BusyBoxProps> = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <SectionSpacingRow marginTop={10} bgcolor={theme.palette.primary.light}>
      <Container>
        <FlexGrowCol>
          <Typography textAlign="center" gutterBottom variant="body1">
            {t('footer:newsletter.supertitle')}
          </Typography>
          <Typography paddingBottom={3} gutterBottom textAlign="center" variant="h3">
            {t('footer:newsletter.title')}
          </Typography>
          <EmailNewsletterTextField />
        </FlexGrowCol>
      </Container>
    </SectionSpacingRow>
  )
}
