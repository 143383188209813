import { AxiosJson } from '@xylabs/axios'
import { assertEx } from '@xylabs/sdk-js'

// restricted client side js key
const ITERABLE_API_KEY = 'c6caecabbffe47cba08ba48818ffbf2f'

const iterableBaseUrl = 'https://api.iterable.com/api'
const iterableEventsUrl = `${iterableBaseUrl}/events`
const iterableTrackUrl = `${iterableEventsUrl}/track`

const IterableAxios = new AxiosJson({ headers: { 'Api-Key': ITERABLE_API_KEY } })

export interface UserEmail {
  email: string
}

export interface UserId {
  userId: string
}

export type User = UserEmail | UserId

export type TrackEvent = User & {
  campaignId?: number
  createNewFields?: boolean
  createdAt?: number
  dataFields?: Record<string, unknown>
  eventName: string
  templateId?: number
}

export const iterableTrackEvent = async (event: TrackEvent) => {
  try {
    const result = await IterableAxios.post(iterableTrackUrl, event)
    assertEx(result.status === 200, () => `iterableTrackEvent failed: ${result.status}`)
  } catch (error) {
    console.warn('iterableTrackEvent:fail', event)
    console.error(error)
  }
}
