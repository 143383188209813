import React from 'react'
import { useTranslation } from 'react-i18next'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const ResourcesFooterLinks: React.FC = (props) => {
  const { t } = useTranslation()
  const ResourcesFooterLinkData: FooterLinkProps[] = [
    { linkName: t('footer:links.resources.resources'), to: '/resources' },
    { linkName: t('footer:links.resources.papers'), to: '/papers' },
    { linkName: t('footer:links.resources.partners'), to: '/partners' },
    { linkName: t('footer:links.resources.brandGuidelinesAndLogos'), to: '/brand' },
  ]
  return <FooterLinkLayout links={ResourcesFooterLinkData} sectionTitle={t('footer:links.resources.title')} {...props} />
}
