import type { ToolbarProps } from '@mui/material'
import { Toolbar, useMediaQuery } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexRow } from '@xylabs/react-flexbox'
import { asLinkHrefOrToProps } from '@xylabs/react-link'
import type { ReactNode } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router-dom'

import { LanguageSelect } from '../../components/index.ts'

interface XYOToolbarProps extends ToolbarProps {
  ctaLink?: To | string
  ctaText?: string
  customButton?: ReactNode
}

export const SystemToolBar: React.FC<XYOToolbarProps> = ({
  ctaLink, ctaText, customButton, children, ...props
}) => {
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('lg'))
  const ctaLinkTypeTo: boolean = ctaLink?.toString().includes('https') ? false : true
  const { t } = useTranslation()
  return (
    <Toolbar disableGutters {...props}>
      <FlexRow gap={2}>
        {customButton ?? (
          <ButtonEx
            flexWrap="nowrap"
            color="primary"
            variant="contained"
            size={isMedium ? 'medium' : 'large'}
            sx={{ display: isMedium ? 'none' : 'flex' }}
            target={ctaLinkTypeTo ? undefined : '_blank'}
            {...asLinkHrefOrToProps({
              to: ctaLinkTypeTo ? ctaLink : '/coin',
              href: ctaLinkTypeTo ? '/coin' : ctaLink?.toString(),
            })}
            intent="Learn About COIN"
            placement="navbar-cta"
          >
            {ctaText ?? t('common:earnXYOTokens')}
          </ButtonEx>
        )}
        {children}
        <LanguageSelect hideLabel />
      </FlexRow>
    </Toolbar>
  )
}
