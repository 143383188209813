import React from 'react'
import { useTranslation } from 'react-i18next'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const DeveloperFooterLinks: React.FC = (props) => {
  const { t } = useTranslation()
  const DeveloperFooterLinkData: FooterLinkProps[] = [
    { linkName: t('footer:links.developer.overview'), to: '/developer' },
    { linkName: t('footer:links.developer.openSourceGithub'), href: 'https://github.com/xyoraclenetwork' },
    { linkName: t('footer:links.developer.documentation'), href: 'https://docs.xyo.network' },
    { linkName: t('footer:links.developer.sdks'), to: '/developer' },
  ]
  return <FooterLinkLayout links={DeveloperFooterLinkData} sectionTitle={t('footer:links.developer.title')} {...props} />
}
