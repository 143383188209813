import type { SelectProps } from '@mui/material'
import {
  Box, MenuItem, Select, Typography,
} from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { allLanguages, useNavigateToLanguage } from '../i18n/index.ts'

export type LanguageSelectProps = SelectProps<string> & {
  hideLabel?: boolean
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({ hideLabel, ...props }) => {
  const { i18n } = useTranslation()

  useNavigateToLanguage()

  const onChange = (language: string) => {
    i18n.changeLanguage(language).catch((error) => {
      console.error('LanguageSelect', error)
    })
  }

  const languages = useMemo(() => {
    const supportedLanguages = i18n.options.supportedLngs || []
    return allLanguages.filter(lang => supportedLanguages.includes(lang.code))
  }, [i18n])

  return (
    <Select
      value={i18n.language}
      onChange={(event) => {
        onChange(event.target.value)
      }}
      variant="standard"
      displayEmpty
      renderValue={(value) => {
        const selectedLanguage = languages.find(lang => lang.code === value)
        if (!selectedLanguage) return null

        return (
          <Box display="flex" alignItems="center">
            <Typography variant="h6" marginX={1}>{selectedLanguage.flag}</Typography>
            {hideLabel ? null : <Typography>{selectedLanguage.label}</Typography>}
          </Box>
        )
      }}
      {...props}
    >
      {languages.map(lang => (
        <MenuItem key={lang.code} value={lang.code}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" marginX={1}>{lang.flag}</Typography>
            <Typography>{lang.label}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}
