import type {
  CommonFields,
  PurchaseFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { XyCustomEvent } from '@xylabs/react-pixel'

export class XyEvents {
  custom(name: string) {
    return new XyCustomEvent<CommonFields>(name)
  }

  pageview() {
    return new XyCustomEvent<ViewContentFields>('ViewContent')
  }

  purchase() {
    return new XyCustomEvent<PurchaseFields>('Purchase')
  }

  userClick() {
    return new XyCustomEvent<UserClickFields>('UserClick')
  }

  viewContent() {
    return new XyCustomEvent<ViewContentFields>('ViewContent')
  }
}
