import type { NavigateOptions, To } from 'react-router-dom'

export interface NavbarSectionProps {
  name: string
  sectionItems?: Array<NavBarItemProps>
  to: string | undefined
}

export interface NavBarItemOnlyHrefProps {
  href?: string
  to?: never
  toOptions?: never
}

export interface NavBarItemOnlyToProps {
  href?: never
  to?: To
  toOptions?: NavigateOptions
}

export interface NavBarItemNoToOrHrefProps {
  href?: never
  to?: never
  toOptions?: never
}

export type NavBarItemHrefOrToOrNoProps = NavBarItemOnlyHrefProps | NavBarItemOnlyToProps | NavBarItemNoToOrHrefProps

export interface NavBarItemHrefAndToProps {
  href?: string
  to?: To
  toOptions?: NavigateOptions
}

export const asNavBarItemHrefOrToProps = (props: NavBarItemHrefAndToProps): NavBarItemHrefOrToOrNoProps => {
  if (props.href && (props.to || props.toOptions)) {
    throw new Error('NavBarProps: cannot have both href and to')
  }
  return props.href ? { href: props.href } : props.to ? { to: props.to, toOptions: props.toOptions } : {}
}

export interface NavBarBaseProps {
  name: string
  sectionItems?: Array<NavBarItemProps>
}

export interface BaseNavBarItemProps {
  desc?: string
  disabled?: boolean
  linkText: string
}

export type NavBarItemProps = BaseNavBarItemProps & NavBarItemHrefOrToOrNoProps
