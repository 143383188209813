import React from 'react'
import { useTranslation } from 'react-i18next'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const SupportFooterLinks: React.FC = (props) => {
  const { t } = useTranslation()
  const SupportFooterLinkData: FooterLinkProps[] = [
    {
      linkName: t('footer:links.support.helpCenter'),
      href: 'https://support.xy.company/hc/en-us/categories/360001417734',
    },
    {
      linkName: t('footer:links.support.contactSupport'),
      href: 'https://support.xy.company/hc/en-us/requests/new',
    },
  ]
  return <FooterLinkLayout links={SupportFooterLinkData} sectionTitle={t('footer:links.support.title')} {...props} />
}
