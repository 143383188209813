import type { CommonFields } from '@xylabs/pixel'
import { MixpanelCustomEvent, MixpanelPageViewEvent } from '@xylabs/react-pixel'
import type { Mixpanel } from 'mixpanel-browser'

export class MixpanelEvents<T extends CommonFields = CommonFields> {
  constructor(protected mixpanel: Mixpanel) {}
  custom<T extends CommonFields>(name: string) {
    return new MixpanelCustomEvent<T>(name, this.mixpanel)
  }

  pageview() {
    return new MixpanelPageViewEvent<T>(this.mixpanel)
  }

  purchase() {
    return new MixpanelCustomEvent<T>('Purchase', this.mixpanel)
  }

  userClick() {
    return new MixpanelCustomEvent<T>('UserClick', this.mixpanel)
  }

  viewContent() {
    return new MixpanelCustomEvent<T>('ViewContent', this.mixpanel)
  }
}
