import type { ExperimentsTestDataLoaded } from '../types/index.ts'

/**
 * Deserializes experiments from a string
 * @param data The serialized experiment data
 * @returns The deserialized experiment data
 */
export const deserializeExperiments = (data?: string | null): ExperimentsTestDataLoaded => {
  /**
   * NOTE: This was borrowed from prior work in the coin web-offers project.
   * It is not clear why the data is stored in this format.  It looks to be something like:
   * [ExperimentName (but with not dashes allowed)] dash(-) [Experiment] delineated by a pipe(|)
   */
  const parsed = data?.split('|') ?? []

  /**
   * NOTE: This was borrowed from prior work in the coin web-offers project.
   * It appears to take the pipe delineated data and split it into array of objects.
   * It takes each string and splits it by the dash(-) and assigns the first part as
   * the key and the second part as the value.  Something like:
   * { [ExperimentName]: [Experiment] }
   */
  const acc = {} as ExperimentsTestDataLoaded
  for (const key of parsed) {
    const data = key.split('-')
    // TODO: Handle the data does not exist in the desired shape
    // if (data[0]) acc[data[0]] = data[1]
    acc[data[0]] = data[1]
  }
  return acc
}
