import {
  Divider, Grid2, Typography,
  useTheme,
} from '@mui/material'
import { Container } from '@mui/system'
import { FlexGrowCol, FlexRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import type { FooterProps } from '@xyo-network/react-sdk'
import {
  copyrightLinkTitle, Footer, FooterLink,
} from '@xyo-network/react-sdk'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { allLanguages } from '../../i18n/index.ts'
import { DeveloperFooterLinks } from './DeveloperFooterLinks.tsx'
import { EcosystemFooterLinks } from './EcosystemFooterLinks.tsx'
import { ResourcesFooterLinks } from './ResourcesFooterLinks.tsx'
import { SocialFooterLinks } from './SocialFooterLinks.tsx'
import { SupportFooterLinks } from './SupportFooterLinks.tsx'
import { TokenFooterLinks } from './TokenFooterLinks.tsx'

const footerLinks = [
  {
    href: 'https://xylabs.com/',
    title: copyrightLinkTitle('XY Labs, Inc.'),
  },
  {
    href: 'https://xyo.network/',
    title: 'XYO Foundation',
  },
  {
    href: 'https://xylabs.com/privacy/',
    title: 'Privacy',
  },
  {
    href: 'https://xylabs.com/terms/',
    title: 'Terms',
  },
  {
    href: 'https://xylabs.com/jobs',
    title: 'Careers',
  },
]

export interface XyoFooterStyledProps extends FooterProps {
  landingPageMode?: boolean
}

export const XyoFooterStyled: React.FC<XyoFooterStyledProps> = ({ landingPageMode, ...props }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  return (
    <Footer
      paddingY={4}
      sx={{ backgroundColor: theme.palette.background.paper }}
      {...props}
    >
      <FlexGrowCol alignItems="center">
        {landingPageMode
          ? null
          : (
              <Grid2 spacing={2} container paddingBottom={2}>
                <Grid2 size={{ xs: 12, md: 2 }}>
                  <SocialFooterLinks />
                </Grid2>
                <Grid2 size={{ xs: 6, md: 2 }}>
                  <EcosystemFooterLinks />
                </Grid2>
                <Grid2 size={{ xs: 6, md: 2 }}>
                  <TokenFooterLinks />
                </Grid2>
                <Grid2 size={{ xs: 6, md: 2 }}>
                  <DeveloperFooterLinks />
                </Grid2>
                <Grid2 size={{ xs: 6, md: 2 }}>
                  <ResourcesFooterLinks />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 2 }}>
                  <SupportFooterLinks />
                </Grid2>
              </Grid2>
            )}
        {landingPageMode || !Array.isArray(i18n.options.supportedLngs)
          ? null
          : (
              <Container maxWidth="md">
                <FlexRow gap={1} marginTop={2} flexWrap="wrap">
                  {i18n.options.supportedLngs.map((lng) => {
                    const langInfo = allLanguages.find(lang => lang.code === lng)
                    return langInfo
                      ? (
                          <LinkEx
                            key={lng}
                            sx={{ color: theme.palette.primary.contrastText }}
                            to="#"
                            onClick={() => i18n.changeLanguage(lng).then(() => { navigate(lng === 'en' ? '/' : `/${lng}`) })}
                          >
                            <Typography variant="body2">{langInfo.label}</Typography>
                          </LinkEx>
                        )
                      : null
                  })}
                </FlexRow>
              </Container>
            )}
        {landingPageMode
          ? null
          : <Divider flexItem sx={{ marginY: 3 }} />}
        <FlexRow flexWrap="wrap" textTransform="uppercase" {...props}>
          {footerLinks?.map((footerLink, index) => (
            <FooterLink color="inherit" noWrap key={index} paddingX={1} margin={0} {...footerLink}>
              <small>{footerLink.title}</small>
            </FooterLink>
          ))}
        </FlexRow>
      </FlexGrowCol>
    </Footer>
  )
}
