import { isLocalhost } from '@xylabs/react-shared'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import type Rollbar from 'rollbar'

import { supportedLanguages } from './allLanguages.ts'

declare global {
  var rollbar: Rollbar
}

export const initI18n = () => {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      saveMissing: true,
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        if (!isLocalhost) {
          rollbar?.warn('Missing translation key', {
            language: lng,
            namespace: ns,
            key: key,
            fallbackValue: fallbackValue || '(no fallback provided)',
          })
        }
        console.warn('Missing translation key', {
          language: lng,
          namespace: ns,
          key: key,
          fallbackValue: fallbackValue || '(no fallback provided)',
        })
      },
      backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      ns: [
        'about-xyo',
        'brand',
        'build-the-future',
        'blog',
        'common',
        'common-components',
        'coin',
        'community',
        'developer',
        'ecosystem',
        'email-newsletter',
        'faq',
        'footer',
        'home',
        'navbar',
        'news',
        'roadmap2024',
        'partners',
        'papers',
        'proof-of-location',
        'proof-of-origin',
        'enterprise-contact',
        'ground-truth-verification',
        'resources',
        'root',
        'token-exchanges',
        'token-prices',
        'token-wallets',
        'token',
        'what-is-depin',
        'what-is-xyo',
        'xns',
        'xyos',
      ],
      defaultNS: 'root',
      fallbackLng: 'en',
      supportedLngs: supportedLanguages,
      // lng: 'en', // if you're using a language detector, do not define the lng option
      debug: isLocalhost(),
      detection: {
        order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
        caches: ['localStorage', 'cookie'],
      },
      interpolation: { escapeValue: false },
    }).catch((error) => {
      if (!isLocalhost) {
        rollbar?.error('Failed to initialize i18n', error)
      }
      console.error('Failed to initialize i18n', error)
    })
}
