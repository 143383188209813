/* eslint-disable import-x/no-internal-modules */
import { RedirectWithQuery } from '@xylabs/react-common'
import React, {
  lazy, Suspense, useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import type { RoutesProps } from 'react-router-dom'
import {
  Route, Routes, useLocation,
} from 'react-router-dom'

import { Four04Page } from '../../common/index.ts'

const LazyPapersRoutes = lazy(() => import(/* webpackChunkName: "PapersRoutes" */ '../resources/PapersRoutes.tsx'))
const LazyPartnersRoutes = lazy(() => import(/* webpackChunkName: "PartnersRoutes" */ '../resources/PartnersRoutes.tsx'))
const LazyNewsletterRoutes = lazy(() => import(/* webpackChunkName: "NewsLetterRoutes" */ '../resources/NewsletterRoutes.tsx'))
const LazyNewsRoutes = lazy(() => import(/* webpackChunkName: "NewsRoutes" */ '../resources/NewsRoutes.tsx'))
const LazyHomeRoutes = lazy(() => import(/* webpackChunkName: "HomeRoutes" */ '../home/index.ts'))
const LazyCoinRoutes = lazy(() => import(/* webpackChunkName: "CoinRoutes" */ '../products/coin/index.ts'))
const LazyBrandRoutes = lazy(() => import(/* webpackChunkName: "BrandRoutes" */ '../brand/index.ts'))
const LazyBlogRoutes = lazy(() => import(/* webpackChunkName: "BlogRoutes" */ '../blog/index.ts'))
const LazyXyosRoutes = lazy(() => import(/* webpackChunkName: "XyosRoutes" */ '../products/xyos/index.ts'))
const LazyXnsRoutes = lazy(() => import(/* webpackChunkName: "XnsRoutes" */ '../products/xns/index.ts'))
const LazyRoadmapRoutes = lazy(() => import(/* webpackChunkName: "RoadmapRoutes" */ '../roadmap/index.ts'))
const LazyProofOfLocationRoutes = lazy(() => import(/* webpackChunkName: "ProofOfLocationRoutes" */ '../products/proofOfLocation/index.ts'))
const LazyProofOfOriginRoutes = lazy(() => import(/* webpackChunkName: "ProofOfOriginRoutes" */ '../products/proofOfOrigin/index.ts'))
const LazyGroundTruthVerificationRoutes = lazy(() =>
  import(/* webpackChunkName: "GroundTruthVerificationRoutes" */ '../products/groundTruthVerification/index.ts'))
const LazyCommunityRoutes = lazy(() => import(/* webpackChunkName: "CommunityRoutes" */ '../community/index.ts'))
const LazyEcosystemRoutes = lazy(() => import(/* webpackChunkName: "EcosystemRoutes" */ '../ecosystem/index.ts'))
const LazyEnterpriseRoutes = lazy(() => import(/* webpackChunkName: "EnterpriseRoutes" */ '../enterprise/index.ts'))
const LazyDappRoutes = lazy(() => import(/* webpackChunkName: "DappRoutes" */ '../dapp/index.ts'))
const LazyDocsRoutes = lazy(() => import(/* webpackChunkName: "DocsRoutes" */ '../docs/index.ts'))
const LazyTokenRoutes = lazy(() => import(/* webpackChunkName: "TokenRoutes" */ '../token/index.ts'))
const LazyDeveloperRoutes = lazy(() => import(/* webpackChunkName: "DeveloperRoutes" */ '../developer/index.ts'))
const LazyResourcesRoutes = lazy(() => import(/* webpackChunkName: "ResourcesRoutes" */ '../resources/ResourcesRoutes.tsx'))
const LazyFaqRoutes = lazy(() => import(/* webpackChunkName: "FaqRoutes" */ '../learn/FaqRoutes.tsx'))
const LazyLearnRoutes = lazy(() => import(/* webpackChunkName: "LearnRoutes" */ '../learn/LearnRoutes.tsx'))
const LazyAboutRoutes = lazy(() => import(/* webpackChunkName: "AboutRoutes" */ '../learn/AboutRoutes.tsx'))
const LazyFhrRoutes = lazy(() => import(/* webpackChunkName: "FhrRoutes" */ '../resources/FhrRoutes.tsx'))
const LazySettingsPage = lazy(() => import(/* webpackChunkName: "SettingsPage" */ '../resources/pages/Settings/index.ts'))
const LazyLandingRoutes = lazy(() => import(/* webpackChunkName: "LandingPages" */ '../landing/index.ts'))

export const RootRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <Routes {...props}>
      <Route path="settings" element={<Suspense fallback={<div />}><LazySettingsPage /></Suspense>} />
      <Route
        path="/community/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyCommunityRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/proof-of-location/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyProofOfLocationRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/proof-of-origin/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyProofOfOriginRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/enterprise/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyEnterpriseRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/ground-truth-verification/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyGroundTruthVerificationRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/ecosystem/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyEcosystemRoutes />
          </Suspense>
        )}
      />
      <Route element={<RedirectWithQuery to="/ecosystem" />} path="/network/*" />
      <Route element={<RedirectWithQuery href="https://xylabs.com/investors/trade" />} path="/xylb/*" />
      <Route
        path="/network/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyEcosystemRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/coin/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyCoinRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/dapp/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyDappRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/token/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyTokenRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/developer/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyDeveloperRoutes />
          </Suspense>
        )}
      />
      <Route path="/papers/*" element={<Suspense fallback={<div />}><LazyPapersRoutes /></Suspense>} />
      <Route
        path="/docs/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyDocsRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/learn/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyLearnRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/brand/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyBrandRoutes />
          </Suspense>
        )}
      />
      <Route path="/resources/*" element={<Suspense fallback={<div />}><LazyResourcesRoutes /></Suspense>} />
      <Route path="/faq/*" element={<Suspense fallback={<div />}><LazyFaqRoutes /></Suspense>} />
      <Route path="/about/*" element={<Suspense fallback={<div />}><LazyAboutRoutes /></Suspense>} />
      <Route
        path="/xyos/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyXyosRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/xns/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyXnsRoutes />
          </Suspense>
        )}
      />
      <Route path="/partners/*" element={<Suspense fallback={<div />}><LazyPartnersRoutes /></Suspense>} />
      <Route path="/fhr/*" element={<Suspense fallback={<div />}><LazyFhrRoutes /></Suspense>} />
      <Route path="/newsletter/*" element={<Suspense fallback={<div />}><LazyNewsletterRoutes /></Suspense>} />
      <Route
        path="/roadmap/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyRoadmapRoutes />
          </Suspense>
        )}
      />
      <Route path="/news/*" element={<Suspense fallback={<div />}><LazyNewsRoutes /></Suspense>} />
      <Route
        path="/blog/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyBlogRoutes />
          </Suspense>
        )}
      />
      <Route
        path="/landing/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyLandingRoutes />
          </Suspense>
        )}
      />
      <Route element={<RedirectWithQuery href="https://xylabs.com/privacy" />} path="/privacy" />
      <Route element={<RedirectWithQuery href="https://xylabs.com/terms" />} path="/terms" />
      <Route path="/:tab" element={<Four04Page />} />
      <Route
        path="/*"
        element={(
          <Suspense fallback={<div />}>
            <LazyHomeRoutes />
          </Suspense>
        )}
      />
      <Route path="*" element={<Four04Page />} />
    </Routes>
  )
}

export const LocalizedRootRoutes: React.FC<RoutesProps> = (props) => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const possibleLanguage = location.pathname.split('/').at(1)
  document.documentElement.lang = i18n.language
  useMemo(() => {
    if (possibleLanguage !== i18n.language && Array.isArray(i18n.options.supportedLngs) && i18n.options.supportedLngs?.includes(possibleLanguage)) {
      i18n.changeLanguage(possibleLanguage).catch((error) => {
        console.error('LocalizedRootRoutes', error)
      })
    }
  }, [])
  return (
    <Routes {...props}>
      {
        Array.isArray(i18n.options.supportedLngs)
          ? i18n.options.supportedLngs?.map((lng) => {
            return (
              <Route
                key={lng}
                path={`${lng}/*`}
                element={(
                  <RootRoutes />
                )}
              />
            )
          })
          : null
      }
      <Route path="*" element={<RootRoutes />} />
    </Routes>
  )
}
