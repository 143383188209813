import { XyPixel } from '@xylabs/pixel'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'

import { PixelContext } from './Context.ts'

interface Props {
  id: string
}

export const PixelProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, id } = props
  XyPixel.init(id)

  const value = useMemo(() => ({ pixel: XyPixel.instance }), [id])

  return (
    <PixelContext.Provider value={value}>
      {children}
    </PixelContext.Provider>
  )
}
