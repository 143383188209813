import type { ReactElement } from 'react'
import React, { useMemo, useState } from 'react'

import { SettingsContext } from './Context.ts'

interface AppSettingsLoaderProps {
  children?: ReactElement[] | ReactElement | undefined
}

const LocalStoragePrefix = 'AppSetting|'

enum Settings {
  DarkMode = 'DarkMode',
  DeveloperMode = 'DeveloperMode',
}

export const SettingsLoader: React.FC<AppSettingsLoaderProps> = (props) => {
  const [darkMode, setDarkMode] = useState<boolean>(true)
  const [developerMode, setDeveloperMode] = useState<boolean>(localStorage.getItem(`${LocalStoragePrefix}${Settings.DeveloperMode}`) === 'true')

  const enableDarkMode = (enabled: boolean) => {
    localStorage.setItem(`${LocalStoragePrefix}${Settings.DarkMode}`, enabled ? 'true' : 'false')
    setDarkMode(enabled)
  }
  const enableDeveloperMode = (enabled: boolean) => {
    localStorage.setItem(`${LocalStoragePrefix}${Settings.DeveloperMode}`, enabled ? 'true' : 'false')
    setDeveloperMode(enabled)
  }

  const value = useMemo(() => ({
    darkMode,
    developerMode,
    enableDarkMode,
    enableDeveloperMode,
  }), [darkMode, developerMode])

  return (
    <SettingsContext.Provider
      value={value}
      {...props}
    />
  )
}
