import { getLocalStorageObject, setLocalStorageObject } from '@xylabs/react-shared'

import type { ExperimentsHandlerParams } from './ExperimentsHandlerParams.ts'
import { deserializeExperiments, serializeExperiments } from './lib/index.ts'
import type {
  ExperimentRecord, ExperimentsTestDataLoaded, Outcomes,
} from './types/index.ts'

export const OutcomesLocalStorageKey = 'outcomes'
export const ExperimentsLocalStorageKey = '_xyoTestData'

export class ExperimentsHandler {
  private _experimentName: string
  private _experiments: ExperimentRecord

  constructor(params: ExperimentsHandlerParams) {
    const { experiments, experimentName } = params
    this._experiments = experiments
    this._experimentName = experimentName
  }

  /**
   * The user data string stored in local storage
   */
  static get userData(): string | undefined {
    return localStorage.getItem(ExperimentsLocalStorageKey) ?? undefined
  }

  private get experimentName(): string {
    return this._experimentName
  }

  private get experiments(): ExperimentRecord {
    return this._experiments
  }

  /**
   * Loads the serialized experiment data from storage
   * @returns The loaded experiment data or an empty object if none is found
  */
  private static loadExperimentsTestData = () => deserializeExperiments(localStorage.getItem(ExperimentsLocalStorageKey))

  /**
   * Loads the serialized outcome data from storage
   * @returns The loaded outcome data or an empty object if none is found
  */
  private static loadOutcomes = () => getLocalStorageObject<Outcomes>(OutcomesLocalStorageKey) || {}

  /**
   * Persists the serialized experiment data to storage
   * @returns
   */
  private static saveExperimentsTestData = (experimentsTestData: ExperimentsTestDataLoaded) =>
    localStorage.setItem(ExperimentsLocalStorageKey, serializeExperiments(experimentsTestData))

  /**
   * Persists the outcomes to storage
   * @returns
   */
  private static saveOutcomes = (outcomes: Outcomes) => setLocalStorageObject(OutcomesLocalStorageKey, outcomes)

  /**
   * Assigns the user to an experiment variant if unassigned, otherwise returns the assigned variant
   * @returns The assigned experiment variant
   */
  assignExperiment = (): string => {
    // Check saved experiments
    let assignedVariant = this.getAssignedExperiment()
    if (assignedVariant !== undefined) return assignedVariant

    // Check saved outcomes
    let assignedWeight = this.getAssignedOutcome()
    if (assignedWeight === undefined) {
      // Get the total weight of all experiments
      const totalWeight = Object.values(this.experiments).reduce((total, weight) => total + weight, 0)
      // Generate a random number between 0 and totalWeight
      assignedWeight = Math.random() * totalWeight
      // Save the outcome
      this.setAssignedOutcome(assignedWeight)
    }
    // Set the assigned variant based on the assigned weight
    assignedVariant = this.getExperimentBasedOnOutcome(assignedWeight)
    // Save the assigned variant to the experiments test data
    this.setAssignedExperiment(assignedVariant)
    // Return the assigned variant
    return assignedVariant
  }

  /**
   * Gets the experiments data for this experiment
   * @returns The experiments data for this experiment or undefined if none is found
   */
  getAssignedExperiment = (): string | undefined => {
    const existing = ExperimentsHandler.loadExperimentsTestData()
    return existing[this.experimentName]
  }

  /**
   * Gets the outcome data for this experiment
   * @returns The outcome data for this experiment or undefined if none is found
   */
  getAssignedOutcome = (): number | undefined => {
    const existing = ExperimentsHandler.loadOutcomes()
    return existing[this.experimentName]
  }

  /**
   * Updates the experiments data with the provided data, overwriting any existing data with the
   * same name as the supplied keys but preserves any other existing data
   * @param value The value for teh experiments data to update
   * @returns The updated experiments data
   */
  protected setAssignedExperiment = (value: string) => {
    const existing = ExperimentsHandler.loadExperimentsTestData()
    const updated = { ...existing }
    updated[this.experimentName] = value
    ExperimentsHandler.saveExperimentsTestData(updated)
    return updated
  }

  /**
   * Sets the outcome data for this experiment to the provided value, overwriting any
   * existing data but preserves any other existing outcome data
   * @param value The value for the outcome data to update
   * @returns The updated outcomes data
   */
  protected setAssignedOutcome = (value: number) => {
    const existing = ExperimentsHandler.loadOutcomes()
    const updated = { ...existing }
    updated[this.experimentName] = value
    ExperimentsHandler.saveOutcomes(updated)
    return updated
  }

  /**
   * Assigns the user to an experiment variant based on the assigned weight
   * @param assignedWeight The random weight assigned to the user
   * @returns The experiment variant the user is assigned to
   */
  private getExperimentBasedOnOutcome = (assignedWeight: number) => {
    // Initially, set the variant to the first one
    let assignedVariant = Object.keys(this.experiments)[0]

    // Loop through the experiments and find which variant the random number falls into
    let cumulativeWeight = 0
    for (const [variant, weight] of Object.entries(this.experiments)) {
      // Temporarily assign the current bucket to the variant
      assignedVariant = variant
      // Increment the cumulative weight
      cumulativeWeight += weight
      // If the cumulative weight has surpassed the assigned weight
      // we are inside of our assigned bucket
      if (cumulativeWeight > assignedWeight) break
    }
    return assignedVariant
  }
}
