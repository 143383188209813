import type { CommonFields } from '@xylabs/pixel'
import { FacebookCustomEvent, FacebookStandardEvents } from '@xylabs/react-pixel'

export interface FacebookUserClick extends Record<string, unknown> {
  elementName: string
  elementType: string
}

export class FacebookEvents<T extends CommonFields = CommonFields> extends FacebookStandardEvents<T> {
  custom<T extends CommonFields = CommonFields>(name: string) {
    return new FacebookCustomEvent<T>(name)
  }

  userClick() {
    return new FacebookCustomEvent<FacebookUserClick | T>('UserClick')
  }
}
