import { useTheme } from '@mui/material'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import { webThemeOptions } from '@xyo-network/react-sdk'
import deepmerge from 'deepmerge'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AppBody } from './AppBody.tsx'
import { useSettings } from './contexts/index.ts'
import { isRtlLanguage } from './i18n/index.ts'
import { partialDarkThemeOptions } from './partialDarkThemeOptions.tsx'
import { partialLightThemeOptions } from './partialLightThemeOptions.tsx'
import { overrideThemeOptions } from './theme/index.ts'

export const AppThemeBody: React.FC = () => {
  const { darkMode } = useSettings()
  const theme = useTheme()
  const { i18n } = useTranslation()
  const rtl = isRtlLanguage(i18n.language)
  const overrideOptions = overrideThemeOptions(theme, rtl)
  useMemo(() => {
    const rtl = isRtlLanguage(i18n.language)
    document.documentElement.setAttribute('dir', rtl ? 'rtl' : 'ltr')
  }, [i18n.language])
  return (
    <InvertibleThemeProvider
      dark={darkMode}
      darkOptions={{ direction: overrideOptions.direction, ...partialDarkThemeOptions }}
      lightOptions={{ direction: overrideOptions.direction, ...partialLightThemeOptions }}
      options={deepmerge(webThemeOptions, overrideOptions)}
    >
      <AppBody />
    </InvertibleThemeProvider>
  )
}
