import type {
  CommonFields,
  FunnelStartedFields, PurchaseFields, TestStartedFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { UserEventHandler } from '@xylabs/pixel'
import type { Promisable } from '@xylabs/sdk-js'

import { ExperimentsHandler } from '../../lib/index.ts'
import type {
  XnsEstimateAttemptedFields, XnsEstimateDiscountedFields, XnsEstimateFailureFields, XnsEstimateSuccessFields,
} from './XnsEstimateEventHandlerInterface.ts'
import type {
  XnsPurchaseAttemptedFields, XnsPurchaseFailureFields, XnsPurchaseSuccessFields,
} from './XnsPurchaseEventHandlerInterface.js'
import type {
  XnsReservationAttemptedFields, XnsReservationFailureFields, XnsReservationSuccessFields,
} from './XnsReservationEventHandlerInterface.ts'
import { XyEvents } from './Xy/index.ts'
import type { XyoEventHandlerInterface, XyoNewsletterSignupFields } from './XyoEventHandlerInterface.ts'

export interface Event<TData extends CommonFields = CommonFields> {
  send(fields: TData, eventId?: string): Promisable<void>
}

export interface Events<T extends CommonFields = CommonFields> {
  custom(name: string): Event<T>
  purchase(): Event<PurchaseFields>
  userClick(): Event<UserClickFields>
  viewContent(): Event<ViewContentFields>
}

export class XyoEventHandler<T extends CommonFields = CommonFields> extends UserEventHandler<T> implements XyoEventHandlerInterface<T> {
  private _events = new XyEvents()

  get events(): Events {
    return this._events
  }

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    fields = await this.getDefaultFieldsData<UserClickFields>(fields)
    await this.events.userClick().send(fields)
  }

  async viewContent(fields: ViewContentFields) {
    fields = await this.getDefaultFieldsData<ViewContentFields>(fields)
    await this.events.viewContent().send(fields)
  }

  async xnsEstimateAttempted(fields: XnsEstimateAttemptedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsEstimateAttempted').send(fields)
  }

  async xnsEstimateDiscounted(fields: XnsEstimateDiscountedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsEstimateDiscounted').send(fields)
  }

  async xnsEstimateFailure(fields: XnsEstimateFailureFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsEstimateFailure').send(fields)
  }

  async xnsEstimateSuccess(fields: XnsEstimateSuccessFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsEstimateSuccess').send(fields)
  }

  async xnsPurchaseAttempted(fields: XnsPurchaseAttemptedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsPurchaseAttempted').send(fields)
  }

  async xnsPurchaseFailure(fields: XnsPurchaseFailureFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsPurchaseFailure').send(fields)
  }

  async xnsPurchaseSuccess(fields: XnsPurchaseSuccessFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.purchase().send(fields)
  }

  async xnsReservationAttempted(fields: XnsReservationAttemptedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsReservationAttempted').send(fields)
  }

  async xnsReservationFailure(fields: XnsReservationFailureFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsReservationFailure').send(fields)
  }

  async xnsReservationSuccess(fields: XnsReservationSuccessFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XnsReservationSuccess').send(fields)
  }

  async xyoNewsletterSignup(fields: XyoNewsletterSignupFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('XyoNewsletterSignup').send(fields)
  }

  private getDefaultFieldsData<TFields extends CommonFields>(fields: TFields): Promisable<TFields> {
    const userData = ExperimentsHandler.userData
    fields.testData = userData
    return fields
  }
}
