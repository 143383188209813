import { useTheme } from '@mui/material'
import type { ButtonExProps } from '@xylabs/react-button'
import { ButtonEx } from '@xylabs/react-button'
import React from 'react'

export type ButtonSectionProps = ButtonExProps & {
  buttonText?: string
}

export const ButtonSection: React.FC<ButtonSectionProps> = ({
  sx, buttonText, ...props
}) => {
  const theme = useTheme()
  return (
    <ButtonEx
      marginTop={1}
      marginBottom={1}
      color="primary"
      variant="contained"
      paddingX={3}
      sx={{
        display: props.href || props.to ? 'flex' : 'none',
        marginLeft: { sm: theme.spacing(0), xs: theme.spacing(2) },
        marginRight: { sm: theme.spacing(1), xs: theme.spacing(2) },
        ...sx,
      }}
      {...props}
    >
      {buttonText}
    </ButtonEx>
  )
}
