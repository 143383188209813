import { ArrowBackRounded, CloseRounded } from '@mui/icons-material'
import {
  AppBar,
  IconButton,
  Stack, Toolbar,
  Typography,
} from '@mui/material'
import * as React from 'react'

export interface MobileNavDialogAppBarProps {
  handleAllClose: () => void
  handleGoBack: () => void
  leftIcon?: React.ReactNode
  leftText?: string
  name: string
  rightIcon?: React.ReactNode
}

export const SectionAppBar: React.FC<MobileNavDialogAppBarProps> = ({
  handleAllClose, handleGoBack,
  name,
}) => {
  return (
    <AppBar
      sx={{
        position: 'relative', paddingY: 1, marginBottom: 2,
      }}
      color="transparent"
      elevation={0}
    >
      <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
        <Stack gap={1} flexDirection="row" alignItems="center" onClick={handleGoBack}>
          <IconButton aria-label="menu-button">
            <ArrowBackRounded fontSize="large" />
          </IconButton>
          {' '}
          <Typography variant="button">{name}</Typography>
        </Stack>
        <IconButton aria-label="menu-button" onClick={handleAllClose}>
          <CloseRounded fontSize="large" />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
