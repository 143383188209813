import {
  CurrencyExchangeRounded,
  X,
} from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { XYOIconWhite } from '../../img/index.ts'
import { RoadmapShadow } from '../Dropdown/img/index.ts'
import type { DropdownSectionDataProps } from '../Dropdown/index.ts'

export const useNavbarData = (): Array<DropdownSectionDataProps> => {
  const { t } = useTranslation()

  return [
    {
      cta: {
        ctaIcon: (
          <img
            src={XYOIconWhite}
            style={{
              marginBottom: '20px',
              opacity: 0.2,
              overflow: 'hidden',
              width: '30%',
            }}
          />
        ),
        ctaLink: '/newsletter',
        ctaText: t('navbar:learn:cta:ctaText'),
        ctaTitle: t('navbar:learn:cta:ctaTitle'),
      },
      colItems: {
        title: '',
        items: [
          {
            desc: t('navbar:learn.items.whatIsXyo.desc'),
            linkText: t('navbar:learn.items.whatIsXyo.linkText'),
            to: '/learn/what-is-xyo',
          },
          {
            desc: t('navbar:learn.items.depin101.desc'),
            linkText: t('navbar:learn.items.depin101.linkText'),
            to: '/learn/what-is-depin',
          },
          {
            desc: t('navbar:learn.items.whitePaper.desc'),
            linkText: t('navbar:learn.items.whitePaper.linkText'),
            to: '/papers',
          },
          {
            desc: t('navbar:learn.items.aboutUs.desc'),
            linkText: t('navbar:learn.items.aboutUs.linkText'),
            to: '/about',
          },
          {
            desc: t('navbar:learn.items.faq.desc'),
            linkText: t('navbar:learn.items.faq.linkText'),
            to: '/faq',
          },
        ],
      },
      name: t('navbar:learn.title'),
      to: '/learn',
      title: t('navbar:learn.subtitle'),
    },
    {
      cta: {
        ctaIcon: (
          <CurrencyExchangeRounded
            fontSize="large"
            style={{
              marginBottom: '20px',
              opacity: 0.2,
              color: '#fff',
            }}
          />
        ),
        ctaLink: '/token/exchange',
        ctaText: t('navbar:token:cta:ctaText'),
        ctaTitle: t('navbar:token:cta:ctaTitle'),
      },
      colItems: {
        title: '',
        items: [
          {
            desc: t('navbar:token.items.xyoToken.desc'),
            linkText: t('navbar:token.items.xyoToken.linkText'),
            to: '/token',
          },
          {
            desc: t('navbar:token.items.exchanges.desc'),
            linkText: t('navbar:token.items.exchanges.linkText'),
            to: '/token/exchange',
          },
          {
            desc: t('navbar:token.items.wallets.desc'),
            linkText: t('navbar:token.items.wallets.linkText'),
            to: '/token/wallet',
          },
        ],
      },
      name: t('navbar:token.title'),
      to: '/token',
      title: t('navbar:token.subtitle'),
    },
    {
      cta: {
        ctaIcon: (
          <img
            src={XYOIconWhite}
            style={{
              marginBottom: '20px',
              opacity: 0.2,
              overflow: 'hidden',
              width: '50%',
            }}
          />
        ),
        ctaLink: '/coin',
        ctaText: t('navbar:solutions:cta:ctaText'),
        ctaTitle: t('navbar:solutions:cta:ctaTitle'),
      },
      colItems: {
        title: t('navbar:solutions.items.title'),
        items: [
          {
            desc: t('navbar:solutions.items.ecosystem.desc'),
            linkText: t('navbar:solutions.items.ecosystem.linkText'),
            to: '/ecosystem',
          },
          {
            desc: t('navbar:solutions.items.smartphoneNodes.desc'),
            linkText: t('navbar:solutions.items.smartphoneNodes.linkText'),
            to: '/coin',
          },
          {
            desc: t('navbar:solutions.items.operateXyoNode.desc'),
            linkText: t('navbar:solutions.items.operateXyoNode.linkText'),
            to: '/xyos',
          },
          {
            desc: t('navbar:solutions.items.sovereignDomains.desc'),
            linkText: t('navbar:solutions.items.sovereignDomains.linkText'),
            to: '/xns',
          },
        ],
      },
      colItems2: {
        title: t('navbar:solutions.items2.title'),
        items: [
          {
            desc: t('navbar:solutions.items2.proofOfLocation.desc'),
            linkText: t('navbar:solutions.items2.proofOfLocation.linkText'),
            to: '/proof-of-location',
          },
          {
            desc: t('navbar:solutions.items2.proofOfOrigin.desc'),
            linkText: t('navbar:solutions.items2.proofOfOrigin.linkText'),
            to: '/proof-of-origin',
          },
          {
            desc: t('navbar:solutions.items2.groundTruthVerification.desc'),
            linkText: t('navbar:solutions.items2.groundTruthVerification.linkText'),
            to: '/ground-truth-verification',
          },
        ],
      },
      name: t('navbar:solutions.title'),
      to: '/',
      title: t('navbar:solutions.subtitle'),
    },
    {
      cta: {
        ctaIcon: (
          <X
            fontSize="large"
            style={{
              marginLeft: '-10px',
              marginTop: '-50px',
              opacity: 0.2,
              color: '#fff',
            }}
          />
        ),
        ctaLink: 'https://twitter.com/OfficialXYO',
        ctaText: t('navbar:communityAndNews:cta:ctaText'),
        ctaTitle: t('navbar:communityAndNews:cta:ctaTitle'),
      },
      colItems: {
        title: '',
        items: [
          {
            desc: t('navbar:communityAndNews.items.socialMedia.desc'),
            linkText: t('navbar:communityAndNews.items.socialMedia.linkText'),
            to: '/community',
          },
          {
            desc: t('navbar:communityAndNews.items.news.desc'),
            linkText: t('navbar:communityAndNews.items.news.linkText'),
            to: '/news',
          },
          {
            desc: t('navbar:communityAndNews.items.blog.desc'),
            linkText: t('navbar:communityAndNews.items.blog.linkText'),
            to: '/blog',
          },
          {
            desc: t('navbar:communityAndNews.items.newsletter.desc'),
            linkText: t('navbar:communityAndNews.items.newsletter.linkText'),
            to: '/newsletter',
          },
          {
            desc: t('navbar:communityAndNews.items.partners.desc'),
            linkText: t('navbar:communityAndNews.items.partners.linkText'),
            to: '/partners',
          },
        ],
      },
      name: t('navbar:communityAndNews.title'),
      to: '/community',
      title: t('navbar:communityAndNews.subtitle'),
    },
    {
      cta: {
        ctaIcon: (
          <img
            src={RoadmapShadow}
            style={{
              marginLeft: '-30px',
              marginBottom: '20px',
              opacity: 0.2,
              overflow: 'hidden',
              width: '110%',
            }}
          />
        ),
        ctaLink: '/roadmap/current',
        ctaText: t('navbar:developers:cta:ctaText'),
        ctaTitle: t('navbar:developers:cta:ctaTitle'),
      },
      colItems: {
        title: '',
        items: [
          {
            desc: t('navbar:developers.items.developerOverview.desc'),
            linkText: t('navbar:developers.items.developerOverview.linkText'),
            to: '/developer',
          },
          {
            desc: t('navbar:developers.items.github.desc'),
            linkText: t('navbar:developers.items.github.linkText'),
            href: 'https://github.com/XYOracleNetwork',
          },
          {
            desc: t('navbar:developers.items.nodeXyoNetwork.desc'),
            linkText: t('navbar:developers.items.nodeXyoNetwork.linkText'),
            href: 'https://node.xyo.network',
          },
          {
            desc: t('navbar:developers.items.buildFuture.desc'),
            linkText: t('navbar:developers.items.buildFuture.linkText'),
            to: '/ecosystem/build-the-future',
          },
        ],
      },
      name: t('navbar:developers.title'),
      to: '/developer',
      title: t('navbar:developers.subtitle'),
    },
    {
      cta: {
        ctaIcon: (
          <X
            fontSize="large"
            style={{
              marginLeft: '-10px',
              marginTop: '-50px',
              opacity: 0.2,
              color: '#fff',
            }}
          />
        ),
        ctaLink: 'https://twitter.com/OfficialXYO',
        ctaText: t('navbar:communityAndNews:cta:ctaText'),
        ctaTitle: t('navbar:communityAndNews:cta:ctaTitle'),
      },
      colItems: {
        title: '',
        items: [
          {
            desc: t('navbar:roadmap.items.roadmap2024:desc'),
            linkText: t('navbar:roadmap.items.roadmap2024:linkText'),
            to: '/roadmap',
          },
          {
            desc: t('navbar:communityAndNews.items.newsletter.desc'),
            linkText: t('navbar:communityAndNews.items.newsletter.linkText'),
            to: '/newsletter',
          },
        ],
      },
      name: t('navbar:roadmap.title'),
      to: '/roadmap',
      title: t('navbar:roadmap.subtitle'),
    },
  ]
}
