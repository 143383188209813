import React from 'react'
import { useTranslation } from 'react-i18next'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const TokenFooterLinks: React.FC = (props) => {
  const { t } = useTranslation()
  const TokenFooterLinkData: FooterLinkProps[] = [
    { linkName: t('footer:links.token.title'), to: '/token' },
    { linkName: t('footer:links.token.exchanges'), to: '/token/exchange' },
    { linkName: t('footer:links.token.prices'), to: '/token/price' },
    { linkName: t('footer:links.token.wallets'), to: '/token/wallet' },
    { href: 'https://etherscan.io/address/0x55296f69f40ea6d20e478533c15a6b08b654e758', linkName: 'Contract' },
  ]
  return <FooterLinkLayout links={TokenFooterLinkData} sectionTitle={t('footer:links.token.title')} {...props} />
}
