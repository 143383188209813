import React from 'react'
import { useTranslation } from 'react-i18next'

import type { FooterLinkProps } from './FooterLinkLayout.tsx'
import { FooterLinkLayout } from './FooterLinkLayout.tsx'

export const EcosystemFooterLinks: React.FC = (props) => {
  const { t } = useTranslation()
  const EcosystemFooterLinkData: FooterLinkProps[] = [
    {
      linkName: t('footer:links.ecosystem.overview'),
      to: '/ecosystem',
    },
    {
      linkName: t('footer:links.ecosystem.whitePaper'),
      href: 'https://docs.xyo.network/XYO-White-Paper.pdf',
    },
  ]
  return <FooterLinkLayout links={EcosystemFooterLinkData} sectionTitle={t('footer:links.ecosystem.title')} {...props} />
}
