import { QuestionAnswerRounded } from '@mui/icons-material'
import { Divider } from '@mui/material'
import React from 'react'

import { SubLinkSection, XyoBasicHero } from '../BasicHero/index.ts'
import { XyoBasePage } from '../XyoBasePage.tsx'

export const Four04Page: React.FC = () => {
  return (
    <XyoBasePage title="404">
      <XyoBasicHero
        alignContent="center"
        title="404"
        desc="It looks like that page is no longer available."
        button1Text="Take me home!"
        button1To="/"
        subLinkSection={(
          <SubLinkSection
            icon={<QuestionAnswerRounded />}
            href="https://support.xy.company/hc/en-us/requests/new"
            text1="Think this is a bug?"
            text2="Report it to our team here."
          />
        )}
      />
      <Divider />
    </XyoBasePage>
  )
}
