import type { ExperimentsTestDataLoaded } from '../types/index.ts'

/**
 * Serializes the experiments data to a string
 * @param experimentsTestData The data to serialize
 * @returns The string representation of the data
 */
export const serializeExperiments = (experimentsTestData: ExperimentsTestDataLoaded): string => {
  const dataArray: string[] = []
  for (const key in experimentsTestData) {
    dataArray.push(`${key}-${experimentsTestData[key]}`)
  }
  return dataArray.join('|')
}
