import type { TooltipProps } from '@mui/material'
import {
  lighten,
  styled, Tooltip, tooltipClasses,
} from '@mui/material'
import React from 'react'

export const StyledDropdownSection = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[6],
    color: 'inherit',
    maxWidth: '900px',
    padding: 0,
    border: `1px ${lighten(theme.palette.background.paper, 0.05)} solid`,
    overflow: 'hidden',
  },
}))
